import React from 'react';
import logo from './logo-blanco.png';

function App() {
  return (
    <div className="container">
      <div className="col-md-8 col-sm-6 col-xs-10">
        <img src={logo} alt="EM2" />
        <h1>Hola, soy <strong>Manuel Espinosa</strong>. Diseño y desarrollo sitios web para pequeñas y medianas empresas</h1>
        <h2>Identidad Corporativa</h2>
        <h2>Interfaz y Experiencia de Usuario</h2>
        <h2>Aplicaciones móviles</h2>
        <p className="contacto"> <a href="mailto:manuel@em2.mx">manuel@em2.mx</a> </p>
      </div>
    </div>
  );
}

export default App;
